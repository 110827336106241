* {
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover; /* Ajusta el fondo para cubrir todo el área visible */
    background-attachment: fixed;

    @media (max-width: 900px) {
        background: linear-gradient(230deg, #15202A, #e6e6e6 50%);
        background-size: cover; /* Ajusta el fondo para cubrir todo el área visible */
        background-attachment: fixed;
    }
}
