@import "./mixins";
@import "./variables";

// Reglas globales
html, body {
    height: 100%;
    margin: 0;
    padding: 0 !important;
}

#root {
    height: 100%;
}

.wrapper {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden; /* Asegura que no haya scroll innecesario en el contenedor principal */
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FAFAFA;
    color: #000; /* Cambié a negro porque #fff en un fondo claro no se verá */
    padding: 10px 20px;
    z-index: 1000; /* Asegura que la barra de navegación esté por encima de otros elementos */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); /* Agrega una sombra suave */
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
        margin-left: 18px;
    }
}

.menuIcon {
    margin-right: 10px;
}

.languageSelector {
    position: absolute;
    top: 10px;
    right: 10px;
}

.flags {
    width: 20px;
    height: 18px;
}

.langInput {
    color: #15202A;
    padding: 0 5px 2px 0;
    width: 70px;
    border: none;
    background-color: transparent;
    font-size: 15px;
}
.contactLink {
    position: absolute;
    top: 13px;
    right: 130px;
    color: #15202A;
    text-decoration: none;
    font-size: 15px;
}
.content {
    @include display-flex();
    @include align-items-center();
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 15vh;   
}
.navWrapper {
    display: flex;
    align-items: center;
    img{
        margin-right: 5px;
        border-radius: 4px;
    }
}
.navItem {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    button{
        border: none;
        background-color: transparent;
        padding: 0px;
    }
}
