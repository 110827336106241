@mixin display-flex() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content-center() {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin align-items-center() {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin input-placeholder() {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::placeholder,
    &:-ms-input-placeholder {
        color: #363636;
    }
}

@mixin media-max-width() {
  @media (max-width: 550px) {
    @content;
  }
}
