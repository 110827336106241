@import "./mixins";
@import "./variables";
@import "./extensions";

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    opacity: 80%;
    font-size: 12px;
    color: white;
    z-index: 200;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 2px; /* Opcional, para agregar algo de espacio interno */
}
.footer a {
    color: white;
    text-decoration: none; /* Opcional, para eliminar el subrayado del enlace */
}

.footer a:hover {
    color: white; /* Asegura que el color permanezca blanco al pasar el mouse sobre el enlace */
    text-decoration: underline; /* Opcional, para agregar un subrayado al pasar el mouse */
}
.helpIcon {
    position: fixed;
    bottom: 25px; 
    right: 20px;  
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center; 
    text-align: center; 
    width: 40px; 
    height: 40px; 
    color: #FFF;
    background-color: #EB0000;
    border-radius: 50%; 
    line-height: 1;
}
